function GustoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.1 41">
      <path
        d="M24.1 27.4v-21h-5.7v1.4c-1.9-1.2-4.1-1.9-6.4-1.9-6.6 0-12 5.4-12 12s5.4 12 12 12c2.3 0 4.5-.7 6.4-1.8v.5c0 3.5-2.8 6.3-6.3 6.3-1.2 0-2.4-.4-3.3-1L5.9 39c1.8 1.1 3.9 1.7 6.2 1.7 6.6 0 12.1-5.4 12.1-12-.1-.3-.1-1.1-.1-1.3zM12 24.3c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3 6.3 2.8 6.3 6.3-2.8 6.3-6.3 6.3z"
        fill="#f45d48"
      />
    </svg>
  );
}
export default GustoLogo;
