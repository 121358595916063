import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import {
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  getGustoUserInfo,
  getGustoRedirectUrl,
  setDisconnectedGusto,
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import { setDeskeraInfo, setGustoInfo } from "src/redux/actions/auth";
import SaveIcon from '@mui/icons-material/Save';
import CloseIconMat from '@mui/icons-material/Close';
import Help from '@mui/icons-material/HelpOutlineOutlined';

import { deepClone, isEmpty } from "src/utils/Common";
import ReactTooltip from "react-tooltip";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField } from "@mui/material";
import { GUSTO_DIALOG_FORM_DETAILS } from "./GustoConstants";
import Spinner from "src/components/Spinner";

function Config({ showHelp }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const gustoInfo = useSelector((state) => state.auth.gustoInfo);
  const tenant = useSelector((state) => state.auth.user);
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);

  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [disconnectWarningGusto, setDisconnectWarningGusto] = useState(false);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [disconnectButtonGusto, setDisconnectButtonGusto] = useState(true);
  const [error, setError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogForm, setDialogForm] = useState(deepClone(GUSTO_DIALOG_FORM_DETAILS));
  const [isLoadingGusto, setLoadingGusto] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(showHelp);
  

  useEffect(() => {
    if (deskeraInfo && deskeraInfo.status === 'connected') {
      checkForGusto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deskeraInfo]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  async function checkForGusto() {
    try {
      const gusto = await getGustoUserInfo();
      dispatch(setGustoInfo(gusto));
    } catch (e) {
      dispatch(setGustoInfo(null));
    }
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function getAddGustoAccountURL(formValues) {

    try {
      const deskeraConnectUrl = await getGustoRedirectUrl(formValues);
      setLoadingGusto(false)
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch Finch url");
      setLoadingGusto(false)
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount(tenant.id);
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectGustoAccount(e, accountId) {
    e.preventDefault();
    try {
      await setDisconnectedGusto(tenant.id);
      setDisconnectWarningGusto(false)
      checkForGusto();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));


    } catch {
      setError("Failed to disconnect Gusto account");
    }
  }

  function connectedModelGusto() {
    return (
      <div>
        <div className="border-bottom w-600 mb-2 text-muted">
          <h5>Gusto Account</h5>
        </div>

        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <p className="text-muted">Your Gusto account is Connected</p>
            <div className="d-flex flex-row-reverse">
              {disconnectButtonGusto && (
                <button
                  className="btn text-danger px-4 dk-btn font-weight-bold border border-danger"
                  onClick={(e) => setDisconnectWarningGusto(true)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-danger">
                    <DeleteLogo />
                  </span>
                  <span>Disconnect</span>
                </button>
              )}
              <CSSTransition
                in={disconnectWarningGusto}
                timeout={100}
                classNames="scale-opacity"
                unmountOnExit
                onEnter={() => setDisconnectButtonGusto(false)}
                onExited={() => setDisconnectButtonGusto(true)}
              >
                <div class="dk-alert border-bottom alert-warning border-warning p-2 mb-1" role="alert">
                  <p className="m-0">
                    To proceed with the import and processing of your payroll journal entries,
                    it is necessary to reestablish the connection.<br />
                    Confirming your intention to disconnect will interrupt this process. Are you certain you wish to proceed with the disconnection?
                  </p>
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn dk-btn font-weight-bold text-muted px-3"
                      onClick={(e) => {
                        handleDisconnectGustoAccount(e);
                      }}
                    >
                      <span className="svg-icon svg-baseline svg-warning">
                        <CheckIcon />
                      </span>
                    </button>
                    <button
                      className="btn dk-btn mr-2 px-3"
                      onClick={(e) => setDisconnectWarningGusto(false)}
                    >
                      <span className="svg-icon svg-baseline svg-warning">
                        <CloseIcon />
                      </span>
                    </button>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function connectModalGusto() {
    return (
      <div>
        <div className="border-bottom w-600 mb-2 text-muted">
          <h5>Gusto Account</h5>
        </div>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <div>
              <div className="d-flex mb-2">
                <p className="text-muted">Link your Gusto account via Finch.</p>
              </div>
              <div className="d-flex flex-row-reverse mb-2">
                <button
                  className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                  onClick={(e) => handleAddGustoAccount(e)}
                  disabled={isLoadingGusto}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    {!isLoadingGusto && <PlusLogo />}
                    {isLoadingGusto && <Spinner className="spinner-border-sm" />}
                  </span>
                  <span>Connect Gusto</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn text-success px-4 dk-btn font-weight-bold border border-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-success">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            <button
              className="btn text-danger px-4 dk-btn font-weight-bold border border-danger"
              onClick={(e) => setDisconnectWarning(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-danger">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <div class="dk-alert border-bottom alert-warning border-warning p-2 mb-1" role="alert">
              <p className="m-0">To proceed with the import and processing of your payroll journal entries,
                it is necessary to reestablish the connection.<br />
                Confirming your intention to disconnect will interrupt this process. Are you certain you wish to proceed with the disconnection?
              </p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }

  const handleClose = () => {
    setDialogOpen(false)
  }
  const handleSave = () => {
    let formValues = {}
    let hasError = false;
    dialogForm.forEach(form => {
      formValues[form.id] = form.value
      if (form.validation?.require && isEmpty(form.value?.trim())) {
        form.error = true;
        hasError = true;
      } else {
        form.error = false;
      }
    })
    if (hasError) {
      setDialogForm([...dialogForm])
      return
    } else {
      formValues['state'] = tenant.id
      getAddGustoAccountURL(formValues)
      handleClose()
    }

  }
  async function handleAddGustoAccount(e) {
    // setDialogForm(deepClone(GUSTO_DIALOG_FORM_DETAILS));
    // setDialogOpen(true)
    setLoadingGusto(true)
    getAddGustoAccountURL()

  }
  const onChange = (inputValue, index) => {
    let form = deepClone(dialogForm);
    form[index].value = inputValue;
    form[index].error = (form[index].validation?.require && isEmpty(inputValue)) ? true : false;
    setDialogForm([...form]);
  };


  const getDialog = () => {
    return (
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Gusto Connect
          <ReactTooltip html={true} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <Alert type="warning">
            <b>Note: </b>Add Redirect URI "<b>{process.env.REACT_APP_URL}/oauth/gusto</b>" at <b>{process.env.REACT_APP_GUSTO_URL}/applications</b> {`->`} Select <b>Application</b> {`->`} <b>Redirect URI</b>
          </Alert>
          {dialogForm.map((field, index) => {
            return (
              <><TextField
                autoFocus={field.autoFocus}
                margin={field.margin}
                id={field.id}
                label={`${field.label} ${field.validation?.require ? "*" : ""}`}
                fullWidth={field.fullWidth}
                variant={field.variant}
                value={field.value}
                error={field.error}
                helperText={
                  field.error
                    ? field.helperText
                      ? field.helperText
                      : `Enter a valid ${field.label}`
                    : ""
                }
                onChange={(event) => {
                  onChange(event.target.value, index);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="right">
                      <span className="cursor-pointer" data-tip={field.tooltipMessage} onClick={() => window.open(field.gustoURL, "_self", "noopener")}>
                        <Help />
                      </span>
                    </InputAdornment>
                  ),
                }}



              />

              </>

            );
          })}
        </DialogContent>
        <DialogActions>
          <div className="d-flex  ">
            <button
              className="btn text-danger pl-2 pr-3 dk-btn font-weight-bold border border-danger"
              onClick={(e) => handleClose()}
            >
              <span className=" mr-2 svg-danger">
                <CloseIconMat style={{ height: "0.8em" }} />
              </span>
              <span>Cancel</span>
            </button>
          </div>
          <div className="d-flex  ">
            <button
              className="btn dk-btn font-weight-bold text-success pl-2 pr-3 border border-success"
              onClick={(e) => handleSave()}
            >
              <span className=" mr-2 svg-success">
                <SaveIcon style={{ height: "0.8em" }} />
              </span>
              <span>{`Save & Connect`}</span>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const handleHelpClose = () => {
    setShowHelpDialog(false)
  }
  const handleRedirectToSyncPayroll = () => {
    history.push("/app/payrolls");
  }
  const getHelpDialog = () => {
    return (
      <Dialog open={showHelpDialog}>
        <DialogTitle align="center">{"Instructions"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ textAlign: "justify" }}>
              <div>
                <span className="fw-600">Payroll Screen:</span>
              </div>
              <div className="mt-2">
                <span className="fw-600" style={{ opacity: 0.75 }}>Sync Payroll: </span>
                <span>
                  If you have recently completed the processing of Gusto payrolls,
                  we recommend utilizing the sync payroll functionality to seamlessly import the payroll data into this system
                  and post payroll journal entries.
                </span>
              </div>
              <div className="mt-3">
                <span className="fw-600" style={{ opacity: 0.75 }}>Generate JE: </span>
                <span>
                  Review the category mappings (mapping of chart of accounts) to ensure accuracy in recording the
                  financial transactions associated with the payroll. initiate the process to generate a Journal Entry.
                </span>
              </div>
              <div className="mt-4">
                <span className="fw-600">Journal Entry Screen:</span>
              </div>
              <div className="mt-2">
                <span className="fw-600" style={{ opacity: 0.75 }}>Post JE: </span>
                <span>
                  After creating the Journal Entry on the payroll screen, proceed to the Journal Entry screen.
                  Here, review the entry to confirm its accuracy, and then post it to integrate the payroll data
                  effectively into your accounting records.
                </span>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="d-flex flex-row-reverse px-3 pb-3">
            <button
              className="text-green btn dk-btn font-weight-bold border-green px-3"
              onClick={(e) => {
                handleRedirectToSyncPayroll();
              }}
            >
              <span>Sync Payrolls</span>
            </button>
            <button
              className="btn dk-btn mr-3 px-3 font-weight-bold text-muted border border-secondary"
              onClick={(e) => {
                handleHelpClose();
              }}
            >
              <span>Close</span>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <div className="p-4 w-auto">

      <h3 className="mb-4">Account Setup</h3>
      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.status === "connected" ? connectedModal() : connectModal()}
      </div>
      <div>
        {(deskeraInfo.status === "connected") ?
          ((!isEmpty(gustoInfo) && gustoInfo.status === 'connected') ? connectedModelGusto() : connectModalGusto()) : (<></>)
        }
      </div>

      {getDialog()}
      {showHelpDialog && getHelpDialog()}
    </div>
  );
}

export default Config;
