import {
  FOODICS_SET_CONFIG,
  FOODICS_SET_PURCHASES,
  FOODICS_SET_ORDERS,
  FOODICS_SET_INVENTORY,
  FOODICS_SET_ACCOUNTS,
  FOODICS_SET_JOB,
  FOODICS_SET_PRODUCT_CONFIG,
  FOODICS_SET_UOM_CONFIG,
  FOODICS_SET_PAYMENT_CONFIG,
  FOODICS_SET_PURCHASE_ORDERS,
  
  GUSTO_SET_CATEGORY,
  GUSTO_SET_PAYROLL,
  GUSTO_SET_JE
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  inventory: {
    loading: true,
    data: [],
  },
  purchases: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  productConfig: {
    loading: true,
    data: {},
  },
  uomConfig: {
    loading: true,
    data: {},
  },
  paymentConfig: {
    loading: true,
    data: {},
  },
  purchaseOrders: {
    loading: true,
    data: {},
  },
  category: {
    loading: true,
    data: [],
  },
  payroll: {
    loading:true,
    data: []
  }
};

function GustoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FOODICS_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case FOODICS_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case FOODICS_SET_PURCHASES:
      return {
        ...state,
        purchases: {
          loading: false,
          data: action.purchases,
        },
      };
    case FOODICS_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
      case GUSTO_SET_CATEGORY:
        return {
          ...state,
          category: {
            loading: false,
            data: action.category,
          },
        };
    case FOODICS_SET_INVENTORY:
      return {
        ...state,
        inventory: {
          loading: false,
          data: action.inventory,
        },
      };
    case FOODICS_SET_JOB:
      return {
        ...state,
        job: {
          loading: false,
          data: action.job,
        },
      };
    case FOODICS_SET_PRODUCT_CONFIG:
      return {
        ...state,
        productConfig: {
          loading: false,
          data: action.productConfig,
        },
      };
    case FOODICS_SET_PAYMENT_CONFIG:
      return {
        ...state,
        paymentConfig: {
          loading: false,
          data: action.paymentConfig,
        },
      };
    case FOODICS_SET_UOM_CONFIG:
      return {
        ...state,
        uomConfig: {
          loading: false,
          data: action.uomConfig,
        },
      };
    case FOODICS_SET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: {
          loading: false,
          data: action.purchaseOrders,
        },
      };
    case GUSTO_SET_PAYROLL:
      return {
        ...state,
        payroll: {
          loading: false,
          data: action.payroll,
        },
      };
    case GUSTO_SET_JE:
      return {
        ...state,
        je: {
          loading: false,
          data: action.je
        }
      }
    default:
      return state;
  }
}

export default GustoReducer;
