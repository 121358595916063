export default class ApiConstants {
    static GUSTO_APP_URL = process.env.REACT_APP_GUSTO_URL;
    static CURRENCY = `/multicurrency/getCurrencyEnum`;

    static IS_SETUP_DONE = false;

    static PAYROLL_PAYLOAD = null;
    static JE_PAYLOAD = null;

    static SYNC_PAYROLL_DATE_RAGE = {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    }

    static PAYROLL_DATE_RAGE = {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    }

    static JE_DATE_RAGE = {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    }

    static setPayrollPayload(payload) {
        ApiConstants.PAYROLL_PAYLOAD = payload;
    }

    static setJEPayload(payload) {
        ApiConstants.JE_PAYLOAD = payload;
    }
}
