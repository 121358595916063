import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import GustoTable from "src/components/GustoTable";
import Alert from "src/components/Alert";
import { useDispatch, useSelector } from "react-redux";
import { loadCategory, } from "src/redux/actions/gusto";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CategoryMapping from "./CategoryMapping";
function Categories() {
  const dispatch = useDispatch();

  const category = useSelector((state) => state.foodics.category);
  const data = useMemo(() => category.data, [category]);
  const productConfig = useSelector((state) => state.foodics.productConfig);
  const config = useSelector((state) => state.foodics.config);

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(loadCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Employer",
        accessor: "employer",
        Cell: ({ cell: { row } }) => {
              return <>{row.values.employer ? "True":"False"}</>;
            }
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ cell: { row } }) => {
              return <div style={{ textTransform: 'capitalize'}}>{row?.values?.type?.toLowerCase()}</div>;
            }
      },
      // {
      //   Header: "Total Amount",
      //   accessor: "totalAmount",
      //   Cell: cell => <span>SAR {cell.value}</span>,
      //   className: "text-right",
      // {
      //   Header: "Sync Status",
      //   accessor: "syncStatus",
      //   Cell: ({ cell: { row } }) => {
      //     return <SyncStatus status={row.original.syncStatus} reason={row.original.reason} id={row.original.cartInvoiceId}/>;
      //   },
      //   disableGlobalFilter: true,
      // },
      
    ],
    []
  );
  
  return (
    <div className="p-4">
      <div className="d-flex justify-content-between">
      <h3 className="mb-3">Categories</h3>
      
      </div>
      

      {(!config || !productConfig)  && <Alert type="warning">
        Your synced category will appear here. Please complete{" "}
        <Link to="/app" className="text-alert-warning">
          <u>Setup</u>
        </Link>{" "}
        before continuing.
      </Alert>}
      <Tabs
        className={'mb-3 '}
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label=""
      >
        <Tab label="Categories" />
        <Tab label="Category - COA Mapping"/>
        
      </Tabs>
      
      {tabValue === 0 && <GustoTable data={data} columns={columns} cart="foodics"  pages = "orders" />}
      {tabValue === 1 && <CategoryMapping data={{ categoryData: data }} />}
    </div>
  );
}

export default Categories;
