import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GustoTable from "src/components/GustoTable";
import Spinner from "src/components/Spinner";
import { loadJE } from "src/redux/actions/gusto";
import { getLongAmoutBlockForLabel, isEmpty } from "src/utils/Common";
import { setGustoInfo } from "src/redux/actions/auth";
import { getGustoUserInfo, syncJEAPI, refreshJEAPI } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import moment from "moment";
import ApiConstants from "src/utils/ApiConstant";
import Loader from "src/components/Loader";


function JournalEntry() {
  const dispatch = useDispatch();

  const gustoInfo = useSelector((state) => state.auth.gustoInfo);
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const tenant = useSelector((state) => state.auth.user);
  const je = useSelector((state) => state.foodics.je);

  const [data, setData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState(null);

  const getDate = (date) => {
    const indofT = date?.indexOf('T') || -1;
    if (indofT !== -1) {
      return date.substring(0, indofT)
    } else {
      return date
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "JE Number",
        accessor: "jeNumber",
      },
      {
        Header: "JE Date",
        accessor: "jeDate",
        Cell: ({ cell: { row } }) => (
          <span>
            {getDate(row.original.jeDate)}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "jeStatus",
        Cell: ({ cell: { row } }) => {
          return <div style={{ textTransform: 'capitalize' }}>
            {row?.values?.jeStatus?.toLowerCase()}
          </div>;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <>
              {row?.values?.jeStatus !== 'SYNCED' && <button
                className="btn text-green dk-btn font-weight-bold  border-green"
                style={{ opacity: isSetupDone() ? 1 : 0.5, cursor: isSetupDone() ? "pointer" : "not-allowed" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setLoaderMessage("Syncing JE... Please wait");
                  syncJE(row)
                }}
              >
                <span>Sync</span>
              </button>}

            </>
          );
        },
        disableGlobalFilter: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const subColumns = useMemo(
    () => [
      {
        Header: "Acount Code",
        accessor: "accountCode",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { row } }) => {
          return getLongAmoutBlockForLabel(row.original.currency, row?.values?.amount)
        }
      },
      {
        Header: 'Type',
        accessor: 'cdType',
        Cell: ({ cell: { row } }) => {
          return <div style={{ textTransform: 'capitalize' }}>
            {row?.values?.cdType?.toLowerCase()}
          </div>;
        },
        disableGlobalFilter: true,
      }
    ],
    []
  );

  useEffect(() => {
    setJEPayload({
      state: tenant.id,
      from: moment(ApiConstants.JE_DATE_RAGE.from).format("YYYY-MM-DD"),
      to: moment(ApiConstants.JE_DATE_RAGE.to).format("YYYY-MM-DD"),
      page: 1,
      limit: 10
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setJEPayload = (payload) => {
    ApiConstants.setJEPayload({
      ...ApiConstants.JE_PAYLOAD,
      ...payload
    });

    getJournalEntries();
  }

  const getJournalEntries = () => {
    dispatch(loadJE(ApiConstants.JE_PAYLOAD));
  }

  useEffect(() => {
    if (deskeraInfo && deskeraInfo.status === 'connected') {
      checkForGusto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deskeraInfo]);

  useEffect(() => {
    if (!isEmpty(gustoInfo) && gustoInfo.status === 'connected') {
      ApiConstants.IS_SETUP_DONE = true;
    }
  }, [gustoInfo]);

  async function checkForGusto() {
    const gusto = await getGustoUserInfo();
    dispatch(setGustoInfo(gusto));
  }

  const isSetupDone = () => {
    return ApiConstants.IS_SETUP_DONE;
  }

  useEffect(() => {
    if (!je?.loading && je?.data) {
      if (Array.isArray(je.data.data)) {
        je.data.data.forEach(je => {
          const currency = je.currency
          je.lineItems.forEach(li => {
            li['currency'] = currency
          })
        });
      }

      setTotalPages(je.data.totalPages);
      setData(je.data.data || []);
    }
  }, [je]);

  useEffect(() => {
    if (data !== null && tenant) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const syncJE = async (row) => {
    try {
      await syncJEAPI({ id: row.original._id, state: tenant.id });
      getJournalEntries();
      setLoaderMessage(null);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Journal Entry has been synced.",
        })
      );
      
    } catch(err) {
      setLoaderMessage(null);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Unable to Sync JE",
        })
      )
    }
  }

  // TODO
  // eslint-disable-next-line no-unused-vars
  const refreshJE = async (row) => {
    await refreshJEAPI({
      payrollId: row.original.payrollId,
      state: tenant.id
    });
    getJournalEntries();
    dispatch(
      addToast({
        type: "success",
        title: "Success",
        message: "Journal Entry has been refreshed.",
      })
    );
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Journal Entry</h3>
      {(!isSetupDone()) && (
        <div class="dk-alert border-bottom alert-warning border-warning p-3 mb-3" role="alert">
          It appears that your current session has expired or you are disconnected.
          In order to resume and continue with the import and processing of your payroll journal entries,
          please take a moment to  connect your Gusto account on the{" "}
          <Link to="/app" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          screen.
        </div>
      )}
      {isLoading &&
        <div className="d-flex justify-content-center align-items-center" style={{ height: '20vh' }}>
          <Spinner className="spinner-border-md" />
        </div>
      }
      {loaderMessage && <Loader message={loaderMessage} />}
      {!isLoading &&
        <GustoTable
          data={data}
          columns={columns}
          subColumns={subColumns}
          cart='foodics'
          pages="inventory"
          onDateFilter={(startDate, endDate) => {
            setJEPayload({
              from: startDate,
              to: endDate
            });
          }}
          onSearch={(searchText) => {
            setJEPayload({
              search: String(searchText).trim() ? String(searchText).trim() : null
            });
          }}
          totalPages={totalPages}
          onPaginationChange={(pageIndex, pageSize) => {
            if (ApiConstants.JE_PAYLOAD) {
              setJEPayload({
                page: pageIndex + 1,
                limit: pageSize
              });
            }
          }}
        />
      }
    </div>
  );
}

export default JournalEntry;
