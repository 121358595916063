import {
    getGustoCategory,
    getJEList,
    getPayrollList
  } from "src/api";
  import {
    
    GUSTO_FAILED_CATEGORY_LOAD,
    GUSTO_SET_CATEGORY,
    GUSTO_SET_PAYROLL,
    GUSTO_FAIL_TO_LOAD_PAYROLL,
    GUSTO_SET_JE,
    GUSTO_FAIL_TO_LOAD_JE,
  } from "../types";
  

  export function loadPayrolls(payload) {
    return async function (dispatch, getState) {
      try {
        const purchases = await getPayrollList(payload);
        dispatch(setPayroll(purchases));
      } catch (err) {
        dispatch(setPayroll([]));
        dispatch(failedPayroll());
      }
    };
  }
  
  
  export function setPayroll(payroll) {
    return {
      type: GUSTO_SET_PAYROLL,
      payroll: payroll,
    };
  }

  export function failedPayroll() {
    return {
      type: GUSTO_FAIL_TO_LOAD_PAYROLL,
    };
  }

  export function loadCategory() {
    return async function (dispatch, getState) {
      try {
        const category = await getGustoCategory();
        dispatch(setCategory(category));
      } catch (err) {
        dispatch(failedCategoryLoad());
      }
    };
  }
  
  export function setCategory(category) {
    return {
      type: GUSTO_SET_CATEGORY,
      category: category,
    };
  }


  export function failedCategoryLoad() {
    return {
      type: GUSTO_FAILED_CATEGORY_LOAD,
    };
  }


  
  export function loadJE(params) {
    return async function (dispatch, getState) {
      try {
        const JE = await getJEList(params);
        dispatch(setJE(JE));
      } catch (err) {
        dispatch(setJE([]));
        dispatch(failedJE());
      }
    };
  }
  
  
  export function setJE(je) {
    return {
      type: GUSTO_SET_JE,
      je: je,
    };
  }

  export function failedJE() {
    return {
      type: GUSTO_FAIL_TO_LOAD_JE,
    };
  }
  