function PayrollIconBlack() {
    return (
        <svg 
        enable-background="new 0 0 24 24"
        height="512"
        viewBox="0 0 24 24"
        width="512">
            <path d="M0 11.9213V17.5974C0 18.2045 0.692206 18.7012 1.54559 18.7012H18.4544C19.3083 18.7012 20 18.2045 20 17.5974V11.913C20 11.0934 18.7933 10.5598 17.7661 10.9246L10.6453 13.4555C10.1983 13.6104 9.71225 13.6104 9.26529 13.4555L2.23946 10.934C1.2144 10.5658 0 11.0995 0 11.9213Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2283 2.10377V9.13533L10.0855 11.3981L2.94263 9.13533V2.10377C2.94263 1.81103 3.05894 1.53028 3.26598 1.32329C3.47302 1.11629 3.75383 1 4.04662 1H16.1243C16.4171 1 16.6979 1.11629 16.905 1.32329C17.112 1.53028 17.2283 1.81103 17.2283 2.10377ZM9.36629 8.84472C9.35374 8.84214 9.34121 8.83948 9.32869 8.83676C8.7007 8.70547 8.40523 8.56046 8.30393 8.51074L8.28321 8.50067C8.00003 8.36656 7.93324 7.98962 8.05799 7.65076C8.20979 7.24126 8.54982 7.25672 8.9169 7.37482C9.08687 7.41942 9.18608 7.45087 9.27435 7.47884L9.27435 7.47884C9.40451 7.5201 9.51086 7.5538 9.7852 7.61103C10.3736 7.7341 10.6905 7.7065 10.7722 7.2799C10.8511 6.86488 10.2864 6.77713 9.94417 6.73243C9.45179 6.66951 8.84018 6.56686 8.42121 6.11818C8.25108 5.92467 8.12379 5.69739 8.04768 5.45124C7.97158 5.20509 7.94838 4.94564 7.97961 4.6899C8.04199 4.18658 8.32571 3.7517 8.77835 3.4603C8.9726 3.33576 9.17028 3.25305 9.36625 3.20255C9.36921 2.68815 9.58973 2.48071 9.98888 2.48071C10.4032 2.48071 10.6054 2.63748 10.6109 3.24283C11.073 3.37389 11.4267 3.58971 11.5527 3.66661L11.5538 3.66726C11.8215 3.83062 11.9303 4.11208 11.7779 4.46087C11.6024 4.86264 11.1707 4.74509 10.8395 4.63692C10.8076 4.62652 10.7711 4.61405 10.7311 4.60035L10.731 4.60034L10.731 4.60033C10.2782 4.44542 9.36324 4.13244 9.2608 4.84884C9.20008 5.27656 9.84426 5.41122 10.1379 5.45592L10.14 5.45624C10.5635 5.52018 11.0438 5.59272 11.4235 5.88308C11.8557 6.21421 12.076 6.75285 12.0131 7.3257C11.9667 7.74789 11.7569 8.12262 11.3904 8.43996C11.1639 8.63608 10.9028 8.77149 10.6109 8.84632C10.6067 9.43097 10.4396 9.72143 9.98888 9.72143C9.51139 9.72143 9.36976 9.39855 9.36629 8.84472Z" />
        </svg>        
    );
}
  
export default PayrollIconBlack;
  