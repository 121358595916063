import { SET_CURRENCY_LIST, SET_CURRENT_USER, SET_DESKERA_INFO, SET_GUSTO_INFO, SET_USER_INFO } from "src/redux/types";

export function setCurrentUser(user, syncEnabled) {
  return {
    type: SET_CURRENT_USER,
    user: user,
    syncEnabled: syncEnabled,
  };
}

export function setDeskeraInfo(deskeraInfo) {
  return {
    type: SET_DESKERA_INFO,
    info: deskeraInfo,
  };
}

export function setGustoInfo(gustoInfo) {
  return {
    type: SET_GUSTO_INFO,
    info: gustoInfo,
  };
}

export function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    info: userInfo,
  };
}

export function setCurrencyList(currency) {
  return {
    type: SET_CURRENCY_LIST,
    info: currency,
  };
}
