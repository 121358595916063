export default class Utility {
    static currencyList;
    
    static getCurrencySymbolFromCode(code) {
        const currencySymbol = Utility.currencyList?.filter(
          (currency) => currency.currencyCode === code
        )[0]?.currencySymbol;
        return currencySymbol ?? '';
      }
}

