import axios from "axios";
import ApiConstants from "src/utils/ApiConstant";

import { CommonDetails } from "src/utils/Common";

const API = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // check whether request was a network failure, or unauthenticated or failed to login status
    if (
      !originalRequest._retry &&
      (!error.response ||
        error.response.status === 401 ||
        originalRequest.url.includes("/login/status"))
    ) {
      // if its refreshtoken request that failed, do not retry
      if (originalRequest.url.includes("getrefreshtoken")) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      try {
        await refreshAccessToken();
      } catch {
        return Promise.reject(error);
      }

      return API(originalRequest);
    }
    throw error;
  }
);

export function setAuthToken(token) {
  API.defaults.headers["x-access-token"] = token;
  CommonDetails.tenantDetails = parseJwt(token);
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export async function getLoginStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_LOGIN_STATUS_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getLogout() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER + '/iam/auth/logout2');
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function refreshAccessToken() {
  const requestBody = {
    refreshToken: localStorage.getItem("deskera-refresh-token"),
  };
  try {
    const resp = await API.post(process.env.REACT_APP_TOKEN_REFRESH_URL, requestBody);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setAccountConfig(config) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/deskera/accounts", config);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraLoginUserInfo(userId) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER + "/users/" + userId);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraRedirectUrl() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/redirectUrl");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getGustoRedirectUrl(parameter) {
  // parameter['redirect_uri'] = process.env.REACT_APP_URL;
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + `/gusto/redirectUrl`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedDeskeraAccount(tenantId) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + `/deskera/disconnect?state=${tenantId}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedGusto(tenantId) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + `/gusto/disconnect?state=${tenantId}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraAccount() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTenantInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_TENANT_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function setDeskeraConnection(connectionInfo) {
  let amazonConnectionUrl = new URL(process.env.REACT_APP_SERVER_URL + "/deskera/oauth");

  amazonConnectionUrl.searchParams.append("code", connectionInfo.code);
  amazonConnectionUrl.searchParams.append("state", connectionInfo.state);

  try {
    const resp = await API.get(amazonConnectionUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setGustoConnection(connectionInfo) {
  let gustoConnectionUrl = new URL(process.env.REACT_APP_SERVER_URL + "/gusto/oauth/token");

  try {
    const resp = await API.post(gustoConnectionUrl.toString(), connectionInfo);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraUserInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getGustoUserInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/gusto/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function connectFoodicsStore(path, body) {
  try {
    await API.post(path, body);
  } catch (err) {
    throw err;
  }
}

// --------------------------------------------------------
//
//            /*Gusto API calls*/
//
// --------------------------------------------------------


export async function getGustoCategory() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/gusto/components");
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getPayrollList(param) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + `/gusto/payrolls/list`, { params: param });
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getPayrollListFilterdateAPI(param) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + `/gusto/sync-payrolls`, { params: param });
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function createJE(payrollId, state) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + `/gusto/refresh-je/${payrollId}?state=${state}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getCOAccount() {
  const param = {
    accountGroups: 'Expenses,Current Liabilities,Other Expenses',
    checkOpeningEditable: false,
    limit: 500,
    page: 0
  }
  const URL = process.env.REACT_APP_SERVER + "/account"
  try {
    const res = await API.get(URL, { params: param })
    return res
  } catch (error) {

  }
}

export async function getCOAMapping(data) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/gusto/mapping");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveCOAMapping(data) {
  try {
    const resp = await API.put(process.env.REACT_APP_SERVER_URL + "/gusto/mapping", data);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getJEList(param) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + `/gusto/je-list`, { params: param });
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncJEAPI(je) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + `/gusto/sync-je/${je.id}?state=${je.state}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getCurrencyList(params) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER + ApiConstants.CURRENCY, { params: params })
    return resp.data
  } catch (err) {
    throw err;
  }
}

export async function refreshJEAPI(je) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + `/gusto/refresh-je/${je.payrollId}?state=${je.state}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}