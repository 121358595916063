import { createTheme } from '@mui/material/styles';
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--app-color');

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor.trim(), // Your desired primary color
    },
    // primary: {
    //     light: '#757ce8',
    //     main: '#3f50b5',
    //     dark: '#002884',
    //     contrastText: '#fff',
    //   },
    //   secondary: {
    //     light: '#ff7961',
    //     main: '#f44336',
    //     dark: '#ba000d',
    //     contrastText: '#000',
    //   }
  },
});

export default theme;