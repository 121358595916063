import Utility from "./Utility";

export class CommonDetails {
  static tenantDetails = null;
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const isEmpty = (value) => {
  if (value === null || value === undefined || value.length === 0)
    return true;
  if (
    typeof value === "object" &&
    Object.prototype.toString.call(value) === "[object Date]"
  )
    return false;
  if (typeof value === "number" && value > 0) return false;
  if (Array.isArray(value) || typeof value === "string") return !value.length;
  return Object.keys(value).length === 0;
};

export const getLongAmoutBlockForLabel = (code, amount) => {
  const html = `${code ? Utility.getCurrencySymbolFromCode(code) + ' ' : ''}${+amount < 0
      ? '(' + getNumber(Math.abs(+amount)) + ')'
      : getNumber(+amount)
    }`;
  return html;
}

export const getNumber = (num) => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(num);
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
