import ApiConstants from "src/utils/ApiConstant";

export const GUSTO_DIALOG_FORM_DETAILS = [
  {
    autoFocus: true,
    margin: "dense",
    id: "client_id",
    label: "Client Id",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null,
    validation:{
      require:true
    },
    gustoURL: `${ApiConstants.GUSTO_APP_URL}/applications`,
    tooltipMessage: `How to get <b>Client ID: </b> <ol><li> Go to: <a href="${ApiConstants.GUSTO_APP_URL}/applications">${ApiConstants.GUSTO_APP_URL}/applications</a> </li><li>Select <b>Application</b></li><li>Select <b>Client ID</b></li></li></ol>`
  },
  {
    margin: "dense",
    id: "client_secret",
    label: "Client Secret",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null,
    validation:{
      require:true
    },
    gustoURL: `${ApiConstants.GUSTO_APP_URL}/applications`,
    tooltipMessage: `How to get <b>Client Secret: </b> <ol><li> Go to: <a href="${ApiConstants.GUSTO_APP_URL}/applications">${ApiConstants.GUSTO_APP_URL}/applications</a> </li><li>Select <b>Application</b></li><li>Select <b>Client Secret</b></li></li></ol>`
  },
  {
    margin: "dense",
    id: "org_token",
    label: "Organisation Token",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null,
    validation:{
      require:true
    },
    gustoURL: `${ApiConstants.GUSTO_APP_URL}/organizations`,
    tooltipMessage: `How to get <b>Organisation Token: </b> <ol><li> Go to: <a href="${ApiConstants.GUSTO_APP_URL}/organizations">${ApiConstants.GUSTO_APP_URL}/organizations</a> </li><li>Select <b>Organizations</b></li><li>Select <b>${process.env.REACT_APP_ENV !== 'production' ? "Developer": ""} API Tokens</b></li></li></ol>`
  }
];

export const GUSTO_CONNECTION_TOKEN = [
  {
    autoFocus: true,
    margin: "dense",
    id: "client_id",
    label: "Client Id",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null,
    validation:{
      require:true
    }
  },
  {
    margin: "dense",
    id: "client_secret",
    label: "Client Secret",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null,
    validation:{
      require:true
    }
  },
  {
    margin: "dense",
    id: "redirect_uri",
    label: "Redirect URL",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null
  },
  {
    autoFocus: false,
    margin: "dense",
    id: "code",
    label: "Code",
    fullWidth: true,
    variant: "outlined",
    error: false,
    value:null,
    validation:{
      require:true
    }
  }
]