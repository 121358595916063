import { NavLink, Route, Switch } from "react-router-dom";
import { useState } from "react";
import Payroll from "./Payroll";
import Categories from "./Categories";
import JournalEntry from "./JournalEntry";
import Config from "./Config";
import CartonBoxIcon from "src/assets/CartonBox";
import CreditCardIcon from "src/assets/CreditCard";
import WrenchIcon from "src/assets/Wrench";
import Logs from "./Logs";
import PayrollIconBlack from "src/assets/PayrollIconBlack";

const menuItems = [
  {
    name: "Setup",
    href: "/app",
    icon: <WrenchIcon />,
  },
  {
    name: "Categories",
    href: "/app/categories",
    icon: <CreditCardIcon />,
  },
  {
    name: "Payrolls",
    href: "/app/payrolls",
    icon: <PayrollIconBlack />,
  },
  {
    name: "Journal Entry",
    href: "/app/je",
    icon: <CartonBoxIcon />,
  },
  // remove for now
  // {
  //   name: "Logs",
  //   href: "/app/logs",
  //   icon: <ContactsIcon />,
  // },
  
];

function Foodics() {
  const [activeVisible, setActiveVisible] = useState(false);
  const [activeTop, setActiveTop] = useState(0);

  return (
    <>
      <div
        className="bg-white py-3 dk-shadow-sm"
        style={{
          minWidth: 240,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          zIndex: 9,
          position: "relative",
        }}
      >
        {activeVisible && (
          <div
            className="ease-in-out-back bg-app"
            style={{
              width: 8,
              height: "3.25rem",
              position: "absolute",
              right: 0,
              top: activeTop,
              
            }}
          ></div>
        )}

        {menuItems.map((item, index) => (
          
          <NavLink
            exact to={item.href}
            className="text-decoration-none px-4 py-3 d-block dk-navlink dk-navlink-hover mb-2 ease-in"
            isActive={(match, location) => {
              if (!match) return false;
              setActiveVisible(true);
              setActiveTop(1 + (3.375 + 0.5) * index + "rem");
              return true;
            }}
            style={{
              fontWeight: 500,
            }}
          >
            <span className="svg-icon svg-icon-lg svg-baseline mr-3">{item.icon}</span>
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="w-100 h-100 overflow-auto" style={{ background: "#f6f6f6" }}>
        <Switch>
          <Route exact path="/app">
            <Config showHelp={false}/>
          </Route>
          <Route exact path="/app/help">
            <Config showHelp={true}/>
          </Route>
          <Route path="/app/categories">
            <Categories />
          </Route>
          <Route path="/app/payrolls">
            <Payroll />
          </Route>
          <Route path="/app/je">
            <JournalEntry />
          </Route>
          <Route path="/app/logs">
            <Logs />
          </Route>
          
        </Switch>
      </div>
    </>
  );
}

export default Foodics;
